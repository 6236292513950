var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "dense": ""
    }
  }, [_c('v-treeview', {
    staticClass: "text-button",
    attrs: {
      "active": _vm.deptActive,
      "items": _vm.departments,
      "open": _vm.deptOpenTree,
      "item-key": "_id",
      "transition": "",
      "hoverable": "",
      "activatable": ""
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.deptActive = $event;
      },
      "update:open": function updateOpen($event) {
        _vm.deptOpenTree = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.countEmployee(item)) + " 人 ")];
      }
    }, {
      key: "prepend",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.children ? _c('v-icon', {
          domProps: {
            "textContent": _vm._s("".concat(item.type === 1 ? 'account_tree' : 'folder_shared'))
          }
        }) : _vm._e()];
      }
    }])
  }), _c('v-btn', {
    staticClass: "mt-2 ma-2",
    attrs: {
      "block": "",
      "depressed": "",
      "color": "primary",
      "disabled": !_vm.selectedDept
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_dept', _vm.selectedDept);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("增加部门 ")], 1)], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', [_c('v-scroll-y-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [!_vm.selectedDept ? _c('div', {
    staticClass: "title font-weight-light",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" 选择一个部门 ")]) : _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar', {
    attrs: {
      "dense": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm.selectedDept.children ? _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("account_tree")]) : _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("folder_shared")]), _vm._v(" " + _vm._s(_vm.selectedDept.name) + " ")], 1), _c('v-spacer'), !_vm.selectedDept.isEdit ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('edit_managers', _vm.selectedDept);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("manage_accounts")]), _vm._v("设置管理者 ")], 1) : _vm._e(), _vm.selectedDept.isEdit ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('edit_dept', _vm.selectedDept);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("create")]), _vm._v("编辑部门 ")], 1) : _vm._e(), _vm.selectedDept.isEdit ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('delete_dept', _vm.selectedDept);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("delete")]), _vm._v("删除部门 ")], 1) : _vm._e()], 1), _c('v-divider'), _vm._l(_vm.selectedDept.children, function (item, i) {
    return _c('v-list-item', {
      key: i,
      attrs: {
        "dense": ""
      },
      on: {
        "click": function click($event) {
          return _vm.doAction('open_dept', item);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.name))])], 1), _c('v-list-item-action', [_c('v-icon', [_vm._v("keyboard_arrow_right")])], 1)], 1);
  }), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_dept', _vm.selectedDept);
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("创建子部门")])], 1), _c('v-list-item-action', [_c('v-icon', [_vm._v("add")])], 1)], 1), _c('v-divider'), _c('v-data-table', {
    attrs: {
      "headers": _vm.headerEmployee,
      "items": _vm.accountList,
      "options": _vm.employee_options,
      "server-items-length": _vm.accountCount,
      "loading": _vm.accountLoading,
      "item-class": _vm.row_class,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "item-key": "_id",
      "dense": "",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.employee_options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v("groups")]), _vm._v("部门人员")], 1), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer'), _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('System_Organize') ? _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mx-2",
                attrs: {
                  "color": "primary",
                  "depressed": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "left": ""
                }
              }, [_vm._v("add")]), _vm._v(" 新增人员 ")], 1)];
            }
          }], null, false, 1864754999)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.createEmployee();
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("add_box")])], 1), _c('v-list-item-title', [_vm._v("通过表单录入")])], 1), _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.batchEmployee();
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("library_add")])], 1), _c('v-list-item-title', [_vm._v("通过文件增加")])], 1)], 1)], 1) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "item.personal.name",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('account-dialogs', {
          attrs: {
            "account": item._id
          },
          on: {
            "UpdateEmployee": function UpdateEmployee($event) {
              return _vm.getEmployee();
            }
          }
        }, [_vm._v(_vm._s(item.personal.name || '*未设置姓名'))])], 1)];
      }
    }, {
      key: "item.personal.gender",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.personal.gender ? '男' : '女'))])];
      }
    }, {
      key: "item.phone",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.phone))])];
      }
    }, {
      key: "item.employer.corp",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.employer.corp ? item.employer.corp : item.employer.ownerId.name))])];
      }
    }, {
      key: "item.employer.areaColor",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.employer.areaColor === 0 ? _c('v-chip', {
          attrs: {
            "label": "",
            "x-small": "",
            "color": "green"
          }
        }) : item.employer.areaColor === 1 ? _c('v-chip', {
          attrs: {
            "label": "",
            "x-small": "",
            "color": "yellow"
          }
        }) : _c('v-chip', {
          attrs: {
            "label": "",
            "x-small": "",
            "color": "red"
          }
        })], 1)];
      }
    }, {
      key: "item.employer.department",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.employer.department.map(function (item) {
          return item.name;
        })))])];
      }
    }, {
      key: "item.employer.roles",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.employer.roles.map(function (item) {
          return item.name;
        })))])];
      }
    }, {
      key: "item.employer.isLeave",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.employer.isLeave ? _c('span', [_vm._v("离职")]) : _c('span', [_vm._v("在职")])])];
      }
    }, {
      key: "item.personal.address",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.personal.address))])];
      }
    }, {
      key: "item.employer.area",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.employer.area.map(function (item) {
          return item.name;
        })))])];
      }
    }])
  })], 2)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "350px"
    },
    model: {
      value: _vm.dialogDept,
      callback: function callback($$v) {
        _vm.dialogDept = $$v;
      },
      expression: "dialogDept"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.dialogTitle) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDept
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _vm.dialogTitle === '新建部门' ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "value": _vm.editedDept.parent.name,
      "label": "上级部门",
      "disabled": "",
      "outlined": ""
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "部门名称",
      "hint": "请输入部门名称",
      "outlined": ""
    },
    model: {
      value: _vm.editedDept.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedDept, "name", $$v);
      },
      expression: "editedDept.name"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.dialogTitle === '指定负责人' ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择部门负责人",
      "multiple": ""
    },
    model: {
      value: _vm.editedDept.managers,
      callback: function callback($$v) {
        _vm.$set(_vm.editedDept, "managers", $$v);
      },
      expression: "editedDept.managers"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.dialogTitle === '编辑部门' ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "rules": [_vm.rules.required],
      "value": _vm.selDeptActive.length > 0 ? _vm.selDeptActive[0].name : '',
      "label": "上级部门",
      "disabled": "",
      "outlined": ""
    }
  }), _c('v-menu', {
    attrs: {
      "transition": "slide-y-transition",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref14) {
        var on = _ref14.on,
          attrs = _ref14.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mb-6",
          attrs: {
            "outlined": "",
            "block": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(_vm._s(_vm.selDeptActive.length > 0 ? _vm.selDeptActive[0].name : '')), _c('v-spacer'), _c('v-icon', {
          attrs: {
            "right": "",
            "dark": ""
          }
        }, [_vm._v("arrow_drop_down")])], 1)];
      }
    }], null, false, 3098580923)
  }, [_c('v-card', [_c('v-card-text', [_c('v-treeview', {
    attrs: {
      "items": _vm.departments,
      "active": _vm.selDeptActive,
      "item-key": "_id",
      "item-disabled": "locked",
      "hoverable": "",
      "activatable": "",
      "return-object": ""
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.selDeptActive = $event;
      }
    }
  })], 1)], 1)], 1), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "部门名称",
      "hint": "请输入部门名称",
      "outlined": ""
    },
    model: {
      value: _vm.editedDept.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedDept, "name", $$v);
      },
      expression: "editedDept.name"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择部门负责人",
      "multiple": ""
    },
    model: {
      value: _vm.editedDept.managers,
      callback: function callback($$v) {
        _vm.$set(_vm.editedDept, "managers", $$v);
      },
      expression: "editedDept.managers"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeDept
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "450px"
    },
    model: {
      value: _vm.dialogEmployee,
      callback: function callback($$v) {
        _vm.dialogEmployee = $$v;
      },
      expression: "dialogEmployee"
    }
  }, [_vm.dialogTitle === '新增人员' ? _c('account-edit', {
    on: {
      "Close": function Close($event) {
        _vm.dialogEmployee = false;
      }
    }
  }) : _vm._e(), _vm.dialogTitle === '导入人员' ? _c('account-import', {
    on: {
      "Close": function Close($event) {
        _vm.dialogEmployee = false;
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }