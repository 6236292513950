<template>
  <div class="fluid">
    <v-row justify="space-between">
      <v-col cols="4" dense>
        <v-treeview
          :active.sync="deptActive"
          :items="departments"
          :open.sync="deptOpenTree"
          item-key="_id"
          transition
          hoverable
          activatable
          class="text-button"
        >
          <template v-slot:append="{ item }">
            {{  countEmployee(item) }} 人
          </template>
          <template v-slot:prepend="{ item }">
            <v-icon
              v-if="item.children"
              v-text="`${item.type === 1 ? 'account_tree' : 'folder_shared'}`"
            ></v-icon>
          </template>
        </v-treeview>
        <v-btn block depressed color="primary" class="mt-2 ma-2" :disabled="!selectedDept" @click="doAction('create_dept', selectedDept)">
          <v-icon left>add</v-icon>增加部门
        </v-btn>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selectedDept"
            class="title font-weight-light"
            style="align-self: center;"
          >
            选择一个部门
          </div>
          <v-card flat v-else>
            <v-toolbar dense flat>
              <v-toolbar-title>
                <v-icon class="mr-2" v-if="selectedDept.children">account_tree</v-icon>
                <v-icon class="mr-2" v-else>folder_shared</v-icon>
                {{selectedDept.name}}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn class="ma-2" depressed color="primary" v-if="!selectedDept.isEdit" @click="doAction('edit_managers', selectedDept)">
                <v-icon left>manage_accounts</v-icon>设置管理者
              </v-btn>
              <v-btn class="ma-2" depressed color="primary" v-if="selectedDept.isEdit" @click="doAction('edit_dept', selectedDept)">
                <v-icon left>create</v-icon>编辑部门
              </v-btn>
              <v-btn class="ma-2" depressed color="primary" v-if="selectedDept.isEdit" @click="doAction('delete_dept', selectedDept)">
                <v-icon left>delete</v-icon>删除部门
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-list-item dense @click="doAction('open_dept', item)" v-for="(item, i) in selectedDept.children" :key="i">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>keyboard_arrow_right</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item dense @click="doAction('create_dept', selectedDept)">
              <v-list-item-content>
                <v-list-item-title>创建子部门</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>add</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-data-table
              :headers="headerEmployee"
              :items="accountList"
              :options.sync="employee_options"
              :server-items-length="accountCount"
              :loading="accountLoading"
              :item-class="row_class"
              loading-text="数据读取中... 请稍后"
              :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
              item-key="_id"
              dense
              transition
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title><v-icon class="mr-2">groups</v-icon>部门人员</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-menu offset-y v-if="currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('System_Organize')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="primary" depressed class="mx-2">
                        <v-icon left>add</v-icon>
                        新增人员
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="createEmployee()">
                        <v-list-item-icon>
                          <v-icon>add_box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>通过表单录入</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="batchEmployee()">
                        <v-list-item-icon>
                          <v-icon>library_add</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>通过文件增加</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <template v-slot:item.personal.name="{ item }">
                <td nowrap="true">
                  <account-dialogs :account="item._id" @UpdateEmployee="getEmployee()">{{item.personal.name || '*未设置姓名'}}</account-dialogs>
                </td>
              </template>
              <template v-slot:item.personal.gender="{ item }">
                <td nowrap="true">{{ item.personal.gender ? '男' : '女' }}</td>
              </template>
              <template v-slot:item.phone="{ item }">
                <td nowrap="true">{{ item.phone }}</td>
              </template>
              <template v-slot:item.employer.corp="{ item }">
                <td nowrap="true">{{item.employer.corp ? item.employer.corp : item.employer.ownerId.name}}</td>
              </template>
              <template v-slot:item.employer.areaColor="{ item }">
                <td nowrap="true">
                  <v-chip label x-small v-if="item.employer.areaColor === 0" color="green"></v-chip>
                  <v-chip label x-small v-else-if="item.employer.areaColor === 1" color="yellow"></v-chip>
                  <v-chip label x-small v-else color="red"></v-chip>
                </td>
              </template>
              <template v-slot:item.employer.department="{ item }">
                <td nowrap="true">{{item.employer.department.map(item => item.name)}}</td>
              </template>
              <template v-slot:item.employer.roles="{ item }">
                <td nowrap="true">{{item.employer.roles.map(item => item.name)}}</td>
              </template>
              <template v-slot:item.employer.isLeave="{ item }">
                <td nowrap="true">
                  <span v-if="item.employer.isLeave">离职</span>
                  <span v-else>在职</span>
                </td>
              </template>
              <template v-slot:item.personal.address="{ item }">
                <td nowrap="true">{{item.personal.address}}</td>
              </template>
              <template v-slot:item.employer.area="{ item }">
                <td nowrap="true">{{item.employer.area.map(item => item.name)}}</td>
              </template>
            </v-data-table>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDept" scrollable persistent max-width="350px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            {{dialogTitle}}
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDept"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text v-if="dialogTitle === '新建部门'">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :rules="[rules.required]"
                    :value="editedDept.parent.name"
                    label="上级部门"
                    disabled
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="editedDept.name"
                    :rules="[rules.required]"
                    type="text"
                    label="部门名称"
                    hint="请输入部门名称"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="dialogTitle === '指定负责人'">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="editedDept.managers"
                    :items="accountList"
                    item-text="personal.name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    chips
                    small-chips
                    label="请选择部门负责人"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="dialogTitle === '编辑部门'">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :rules="[rules.required]"
                    :value="selDeptActive.length > 0 ? selDeptActive[0].name : ''"
                    label="上级部门"
                    disabled
                    outlined
                    style="display:none"
                  ></v-text-field>
                  <v-menu
                    transition="slide-y-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outlined block class="mb-6" v-bind="attrs" v-on="on">{{selDeptActive.length > 0 ? selDeptActive[0].name : ''}}<v-spacer></v-spacer><v-icon right dark>arrow_drop_down</v-icon></v-btn>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-treeview
                          :items="departments"
                          :active.sync="selDeptActive"
                          item-key="_id"
                          item-disabled="locked"
                          hoverable
                          activatable
                          return-object
                        >
                        </v-treeview>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <v-text-field
                    v-model="editedDept.name"
                    :rules="[rules.required]"
                    type="text"
                    label="部门名称"
                    hint="请输入部门名称"
                    outlined
                  ></v-text-field>
                  <v-autocomplete
                    v-model="editedDept.managers"
                    :items="accountList"
                    item-text="personal.name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    chips
                    small-chips
                    label="请选择部门负责人"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDept">取消</v-btn>
            <v-btn color="primary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogEmployee" persistent max-width="450px">
      <account-edit v-if="dialogTitle === '新增人员'" @Close="dialogEmployee = false"></account-edit>
      <account-import v-if="dialogTitle === '导入人员'" @Close="dialogEmployee = false"></account-import>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_DEPT_LIST,
  CREATE_DEPT,
  REMOVE_DEPT,
  FETCH_ACCOUNT_LIST,
  EDIT_DEPT,
} from "@/store/actions.type";
export default {
  data() {
    return {
      tab: null,
      deptActive:[],
      deptOpenTree: [],
      dialogDept: false,
      dialogSelDept: false,
      dialogTitle:'',
      valid: true,
      selDeptActive: [],
      editedDept: {
        _id: '',
        name: '',
        ownerType: 'Dealer',
        ownerId: '',
        managers: [],
        parent: {},
        parentId: '',
        openTree: []
      },
      defaultDept: {
        _id: '',
        name: '',
        ownerType: 'Dealer',
        ownerId: '',
        managers: [],
        parent: {},
        parentId: '',
        openTree: []
      },
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      headerEmployee: [
        { text: '姓名', value: 'personal.name' },
        // { text: '性别', value: 'personal.gender', width: "85px" },
        { text: '手机号码', value: 'phone' },
        { text: '角色', value: 'employer.roles' },
        { text: '区域', value: 'employer.area' },
        { text: '状态', value: 'employer.isLeave' }
      ],
      employee_options: {},
      dialogEmployee: false,
    }
  },
  created() {},
  computed: {
    ...mapGetters([
      "currentUser",
      "departments",
      "deptLoading",
      "currentEmployer",
      'accountLoading',
      'accountList',
      'accountCount']),
    selectedDept () {
      if (!this.deptActive.length) return undefined

      const _id = this.deptActive[0]
      let result = null

      for (let obj of this.departments) {
        result = findId(obj, _id)
        if (result) {
          break
        }
      }
      return result
    },
  },
  watch: {
    dialogDept (val) {
      val || this.closeDept()
    },
    selectedDept () {
      if (this.deptActive.length) this.getEmployee({department: this.deptActive[0]})
    },
    employee_options: {
      handler () {
        this.getEmployee({department: this.deptActive[0]})
      },
      deep: true,
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    goBack() {
      this.$router.push({path: '/distributor/system'});
    },
    createEmployee() {
      this.dialogTitle = '新增人员';
      this.dialogEmployee = true;
    },
    batchEmployee() {
      this.dialogTitle = '导入人员';
      this.dialogEmployee = true;
    },
    getList(open = [], active) {
      this.$store.dispatch(FETCH_DEPT_LIST, 'owner='+ this.currentEmployer.ownerId._id)
        .then(() => {
          addDepth(this.departments)
          this.deptOpenTree = open
          this.deptActive = [active]
        })

    },
    getEmployee(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.employee_options;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      const { key, ownertype, ownerid, department, roles } = query;
      let urlQuery = '';
      if (ownertype && ownertype.abbr) urlQuery += '&ownertype='+ ownertype.abbr;
      if (department && department !== -1) urlQuery += '&department='+ department;
      if (roles && roles._id) urlQuery += '&roles='+ roles._id;
      if (ownerid) urlQuery += '&ownerid='+ ownerid;
      if (key) urlQuery += '&key='+ key;
      if (Object.keys(this.employee_options).length > 0) {
        this.$store.dispatch(FETCH_ACCOUNT_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    closeDept () {
      this.dialogDept = false
      this.$nextTick(() => {
        this.editedDept = Object.assign({}, this.defaultDept)
      })
    },
    doAction(action, item) {
      switch (action) {
        case 'create_dept': {
          if (item && item.depth === 3) {
            this.$store.commit(SET_ERROR, {msg: '不能创建下级部门', color: 'error'});
            return
          }
          this.dialogTitle = '新建部门';
          this.editedDept.parent = item ? item : this.departments[0]
          this.dialogDept = true
          break;
        }
        case 'open_dept': {
          this.deptOpenTree.push(this.selectedDept._id)
          this.deptOpenTree.push(item._id)
          this.getList(this.deptOpenTree, item._id)
          break;
        }
        case 'delete_dept': {
          if (this.countEmployee(item) > 0) {
            this.$store.commit(SET_ERROR, {msg: '请删除部门下人员后再删除部门', color: 'error'});
            return
          }
          if (confirm('确定要删除 '+ item.name +'及下级部门, 删除后该部门员工将移除该部门信息。')) {
            if (confirm('该操作不能撤销或恢复，请再次确认是否删除。')) {
              this.$store.dispatch(REMOVE_DEPT, item._id)
                .then(() => {
                  this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'});
                  this.deptOpenTree.pop()
                  this.getList(this.deptOpenTree, item.parentId)
                })
                .catch((error) => {
                  this.$store.commit(SET_ERROR, {msg: error});
                })
            }
          }
          break;
        }
        case 'edit_dept': {
          let parent = null
          for (let obj of this.departments) {
            parent = findId(obj, item.parentId)
            if (parent) {
              break
            }
          }
          this.dialogTitle = '编辑部门';
          this.editedDept['_id'] = item._id;
          this.editedDept['name'] = item.name;
          this.editedDept['managers'] = item.managers;
          this.editedDept['openTree'] = this.deptOpenTree;
          this.editedDept['parentId'] = item.parentId;
          this.selDeptActive = [parent];
          this.dialogDept = true;
          break;
        }
        case 'edit_managers': {
          this.dialogTitle = '指定负责人';
          this.editedDept['_id'] = item._id;
          this.editedDept['managers'] = item.managers;
          this.dialogDept = true;
          break;
        }
        case 'reload_tree' : {
          this.getEmployee({department: this.deptActive[0]})
          this.getList(this.deptOpenTree, this.deptActive[0])
          break;
        }
      }
    },
    submit() {
      switch (this.dialogTitle) {
        case '新建部门': {
          let strInsert = {}
          strInsert.name = this.editedDept.name
          strInsert.parentid = this.editedDept.parent._id
          this.$store.dispatch(CREATE_DEPT, strInsert)
            .then((data)=> {
              this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'success'});
              this.deptOpenTree.push(data.parentId)
              this.deptOpenTree.push(data._id)
              this.getList(this.deptOpenTree, data._id)
              this.closeDept()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break;
        }
        case '编辑部门': {
          if (!this.selDeptActive.length) {
            this.$store.commit(SET_ERROR, {msg: '必须选择上级部门', color: 'error'});
            return
          }
          let strUpdate = {}
          let id = this.editedDept._id
          strUpdate._id = id
          strUpdate.name = this.editedDept.name
          strUpdate.parentid = this.selDeptActive[0]._id
          strUpdate.managers = this.editedDept.managers
          if (id === strUpdate.parentid) {
            this.$store.commit(SET_ERROR, {msg: '上级部门不能设置为自身', color: 'error'});
            return
          }
          this.$store.dispatch(EDIT_DEPT, strUpdate)
            .then((data)=> {
              this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'success'});
              this.deptOpenTree.push(data.parentId)
              this.deptOpenTree.push(data._id)
              this.getList(this.deptOpenTree, data._id)
              this.closeDept()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error});
            })
          break;
        }
        case '指定负责人': {
          let strUpdate = {}
          let id = this.editedDept._id
          strUpdate._id = id
          strUpdate.managers = this.editedDept.managers
          this.$store.dispatch(EDIT_DEPT, strUpdate)
            .then((data)=> {
              this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'success'});
              this.deptOpenTree.push(data.parentId)
              this.deptOpenTree.push(data._id)
              this.getList(this.deptOpenTree, data._id)
              this.closeDept()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error});
            })
          break;
        }
      }
    },
    countEmployee(obj, countKey = 'employeeCount') {
      if (obj.type === 1) {
        return obj[countKey];
      } else {
        return sumChildren(obj);
      }
    },
    getDeptList(obj) {
      let arrObj = [];
      arrObj.push({name: obj.name, value: obj._id})
      if (obj.children) {
        for (let item_1 of obj.children) {
          arrObj.push({name: obj.name +'>>'+ item_1.name, value: item_1._id})
          if (item_1.children) {
            for (let item_2 of item_1.children) {
              arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name, value: item_2._id})
              if (item_2.children) {
                for (let item_3 of item_2.children) {
                  arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name +'>>'+ item_3.name, value: item_3._id})
                }
              }
            }
          }
        }
      }
      return arrObj;
    },
    row_class(item) {
      if (item.isBlock) {
        return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
      }
    }
  },
  directives: {}
}
function findId(obj, targetId) {
  if (obj._id === targetId) return obj
  if (obj.children) {
    for (let item of obj.children) {
      let check = findId(item, targetId)
      if (check) return check
    }
  }
  return null
}
function sumChildren(obj, key = 'employeeSum') {
  let count = obj[key]
  if (obj.children) {
    for (let item of obj.children) {
      count += sumChildren(item)
    }
  }
  return count
}
function addDepth(arr, depth = 0) {
  arr.forEach(obj => {
    obj.depth = depth
    depth === 3 ? obj.locked = true : obj.locked = false;
    addDepth(obj.children, depth + 1)
  })
}

</script>
<style scoped>
</style>
